import React, { useState, useEffect } from 'react';
import styles from './ContactTable.module.css'; // Adjust the path as necessary and consider renaming to match the component
import './ContactTable.module.css'; 
const ContactTable = () => {
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Update the fetch URL to match your contacts endpoint
                const response = await fetch('https://www.bkmcommunity.co.uk/api/contacts');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const jsonData = await response.json();
                console.log("Fetched data:", jsonData); // For debugging
                setContacts(jsonData);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };
    
        fetchData();
    }, []);
    

    // Render the table
    return (
        <div className={styles.main}>
        <table className={styles.table}>
            <thead>
                <tr className={styles.tr}>
                    <th className={styles.th}>Name</th>
                    <th className={styles.th}>Email</th>
                    <th className={styles.th}>Phone</th>
                    <th className={styles.th}>Details</th>
                </tr>
            </thead>
            <tbody>
                {contacts.map((contact) => (
                    <tr className={styles.tr} key={contact._id}>
                        <td className={styles.td}>{contact.name}</td>
                        <td className={styles.td}>{contact.email}</td>
                        <td className={styles.td}>{contact.phone ? contact.phone : 'N/A'}</td>
                        <td className={styles.td}>{contact.details ? contact.details : 'N/A'}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        </div>
    );
};

export default ContactTable;
