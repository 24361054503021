import React, { useState } from "react";
import './Contact.css';
import { TextField, Button, Container, Typography } from "@mui/material";
import axios from "axios";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    details: "",
  });

  const [agreed, setAgreed] = useState(false);
  const [isContactSaved, setIsContactSaved] = useState(false);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (agreed) {
      axios
        .post("https://www.bkmcommunity.co.uk/api/save-contact", formData) // Adjust the URL as necessary
        .then((response) => {
          console.log(response.data.message);
          setIsContactSaved(true);
        })
        .catch((error) => {
          console.error("Error saving contact:", error);
        });
    } else {
      console.log("Please agree to the terms before submitting.");
    }
  };

  return (
    <div className="contact-page-wrapper">
      <Container>
        <Typography variant="h6" align="center" gutterBottom className="head-contact">
          Contact Form
        </Typography>
        <div className="contact-form-container">
          <TextField
            label="Name"
            variant="outlined"
            name="name"
            fullWidth
            margin="dense"
            value={formData.name}
            onChange={handleInputChange}
          />
          <TextField
            label="Email"
            variant="outlined"
            name="email"
            fullWidth
            margin="dense"
            value={formData.email}
            onChange={handleInputChange}
          />
          <TextField
            label="Phone"
            variant="outlined"
            name="phone"
            fullWidth
            margin="dense"
            value={formData.phone}
            onChange={handleInputChange}
          />
          <TextField
            label="Details"
            variant="outlined"
            name="details"
            multiline
            rows={4}
            fullWidth
            margin="dense"
            value={formData.details}
            onChange={handleInputChange}
          />

          <label className="checkbox-label">
            <input
              type="checkbox"
              checked={agreed}
              onChange={(e) => setAgreed(e.target.checked)}
            />
            I agree to the terms and conditions.
          </label>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={!agreed}
          >
            Submit
          </Button>

          {isContactSaved && (
            <Typography variant="body2" color="success.main" align="center" sx={{ mt: 2 }}>
              Contact saved successfully!
            </Typography>
          )}
        </div>
      </Container>
    </div>
  );
};

export default ContactForm;
