import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import './Gallery.css'; // Import the CSS file

function Gallery() {
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemType, setSelectedItemType] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [videoUrls] = useState([
    "https://www.youtube.com/embed/wNcVucE3Ivo",
    "https://www.youtube.com/embed/w0qyLq7AanA",
    "https://www.youtube.com/embed/3-kTlFkbZgM",
    "https://www.youtube.com/embed/aWK8R8gM97U",
    "https://www.youtube.com/embed/IGA1TZIeY9Q",
    "https://www.youtube.com/embed/ua5ljmBVshk",
    "https://www.youtube.com/embed/6hRY5P60obs",
    "https://www.youtube.com/embed/8hKI97WVgpc",
    "https://www.youtube.com/embed/PVzpBCOJBKA",
    "https://www.youtube.com/embed/g0CYGRScyJI"
  ]);

  useEffect(() => {
    fetchImages(); // Invoke fetchImages on component mount
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get('http://localhost:5000/images');
      setImageUrls(response.data);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const openFullScreen = (item, type) => {
    setSelectedItem(item);
    setSelectedItemType(type);
  };

  const closeFullScreen = () => {
    setSelectedItem(null);
    setSelectedItemType(null);
  };
  // Log imageUrl.url here
  return (
    <div className="gallery-container">
      <h2 className="gallery-heading">Images Gallery</h2>
      <Grid container spacing={2}>
        {/* Render Images */}
        {imageUrls.map((imageUrl, key) => {
  console.log("Image URL:", imageUrl.url); 
  return (
    <Grid item xs={12} sm={6} md={4} key={key}>
      <div className="gallery-item-container" onClick={() => openFullScreen(imageUrl.url, 'image')}>
        <img
          src={imageUrl.url}
          alt={` ${key + 1}`} 
          className="gallery-item"
          loading="lazy" // Lazy load images
        />
      </div>
    </Grid>
  );
})}
      </Grid>
      
      {/* Videos Gallery */}
      <h2 className="gallery-heading">Videos Gallery</h2>
      <Grid container spacing={2}>
        {/* Render Videos */}
        {videoUrls.map((videoUrl, key) => (
          <Grid item xs={12} sm={6} md={4} key={key}>
            <div className="gallery-item-container" onClick={() => openFullScreen(videoUrl, 'video')}>
              <iframe
                title={`Video ${key + 1}`}
                src={videoUrl}
                allowFullScreen
                className="gallery-item"
              ></iframe>
            </div>
          </Grid>
        ))}
      </Grid>
      
      {/* Fullscreen Overlay */}
      {selectedItem && selectedItemType === 'image' && (
        <div className="fullscreen-overlay" onClick={closeFullScreen}>
          <CloseIcon className="close-button" onClick={closeFullScreen} />
          <img src={selectedItem} alt="Fullscreen" className="fullscreen-item" />
        </div>
      )}
    </div>
  );
}

export default Gallery;
