import React from 'react';
import './Education.css'; // Import the CSS file

function Education() {
  return (
    <div className="education-container">
      <div className="education-card">
        <div className="education-content">
          <h2>Education</h2>
          <p>Our Education offerings include:</p>
          <ul>
            <li>Monthly Quraan Lectures</li>
            <li>Quarterly main religious gatherings to support your religious beliefs</li>
            <li>Weekly online Quraan and Adees class</li>
            <li>Arabic and Urdu classes</li>
            <li>Free Maths, English, and Science tuition classes for GCSE students</li>
           </ul>
           <p>For terjuma quran tafseer and fiqa masail email us</p>
          <ul>
            <li>moinshahjee@hotmail.com</li>
            <li>smmoinhussain@gmail.com</li>
            <li>00923215841926,00923007744884,</li>
            <li>00923351214172,07727874307</li>
            <li>smmoinhussain@gmail.com</li>
          </ul>
           
            
           
          
          
        </div>
      </div>
    </div>
  );
}

export default Education;
