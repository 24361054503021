import React from 'react';
import './Aboutt.css';
import image0 from '../Assets/00.jpg';
import image1 from '../Assets/11.jpg';
import image2 from '../Assets/22.jpg';
import image3 from '../Assets/33.jpg';
import image4 from '../Assets/44.jpg';
import image5 from '../Assets/55.jpg';
import image6 from '../Assets/66.jpg';
import image7 from '../Assets/77.jpg';
const AboutUs = () => {
  // List of image URLs
  const imageList = [image0,image1, image2, image3, image4, image5,image6,image7];

  return (
    <div className="about-us">
      <h2>Shajra Shareef</h2>
      <p>Hasab Nasab and shijra shareef cosider bellow.</p>
      <div className="image-container">
      {imageList.map((image, index) => (
          <img key={index} src={image} alt={`${index + 1}`} />
        ))}
      </div>
    </div>
  );
}

export default AboutUs;
