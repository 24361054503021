import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Image_Gallery.css'; // Import the CSS file

function ImageGallery() {
  const [images, setImages] = useState([]);
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get('http://localhost:5000/images');
      setImages(response.data);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('image', file);

      const response = await axios.post('http://localhost:5000/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Image uploaded:', response.data);
      fetchImages();
      setUploadStatus('success');
    } catch (error) {
      console.error('Error uploading image:', error);
      setUploadStatus('error');
    }
  };

  const handleDelete = async (key) => {
    try {
      await axios.delete(`http://localhost:5000/images/${key}`);
      console.log('Image deleted');
      fetchImages();
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  return (
    <div className="image-gallery-container">
      <div className="form-container">
        <input type="file" onChange={handleImageChange} />
        <button onClick={handleUpload}>Upload</button>
      </div>

      {uploadStatus === 'success' && (
        <div className="upload-success-message">Image uploaded successfully!</div>
      )}

      <div className="image-container">
        {images.map((image) => (
          <div className="image-card" key={image.id}>
            <img
              className="image"
              src={image.url}
              alt="Uploaded"
            />
            <button className="delete-button" onClick={() => handleDelete(image.key)}>Delete</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ImageGallery;
