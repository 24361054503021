import React, { useState } from "react";
import background from "../Assets/shadi.jpg";
import {
  TextField,
  Button,
  Container,
  Paper,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import "./MatrimonialRegistrationForm.css";

const MatrimonialRegistrationForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    livingInCity: "",
    livingInArea: "",
    age: "",
    qualification: "",
    nationality: "",
    sect: "",
    caste: "",
    familyOriginCountry: "",
    familyOriginCity: "",
    contactNo: "",
    email: "",
    familyRef: "",
    fatherName: "",
    language: "",
    home: "",
    ownBusiness: false,
    driving: "",
    anyDisability: "",
    maritalStatus: "",
   
    numOfChildren: "",
    childrenLiveWithYou: "",
    hobbies : "",
    
    notes: "",
  });

  const [agreed, setAgreed] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;

    if (type === "checkbox") {
      if (name === "ownBusiness" || name === "hobbies") {
        setFormData({
          ...formData,
          [name]: checked,
        });
      } else {
        setFormData({
          ...formData,
          [name]: checked,
        });
      }
    } else if (type === "radio" && name === "driving") {
      const updatedValue = checked ? value : ""; // Set to empty string when "No" is selected
      setFormData({
        ...formData,
        [name]: updatedValue,
      });
    } else {
      const updatedValue = type === "checkbox" ? checked : value;
      setFormData({
        ...formData,
        [name]: updatedValue,
      });
    }
  };

  const handleButtonClick = () => {
    if (agreed) {
      axios
        //.post("https://www.bkmcommunity.co.uk/send-email-matrimonial", formData)
       .post("https://www.bkmcommunity.co.uk/api/save-matrimonial", formData) 
        .then((response) => {
          console.log(response.data.message);
          setIsFormSubmitted(true);

        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        });
    } else {
      console.log("Please agree to the terms before submitting.");
    }
  };

  return (
    <div className="matrimonial-form-wrapper">
      <Container component={Paper} sx={{ p: 4 }}>
        <img src={background} alt="Background" className="background-image" />
        <Typography className="heading" variant="h4" align="center">
          Registration Form For BKM Matrimonial Service
        </Typography>

        <div className="matrimonial-form-container">
          <TextField
            label="Name"
            variant="outlined"
            name="name"
            fullWidth
            required
            value={formData.name}
            onChange={handleInputChange}
          />
          <div className="input-group">
            <TextField
              label="Living in City"
              variant="outlined"
              name="livingInCity"
              fullWidth
              required
              value={formData.livingInCity}
              onChange={handleInputChange}
            />
            <TextField
              label="Area"
              variant="outlined"
              name="livingInArea"
              fullWidth
              required
              value={formData.livingInArea}
              onChange={handleInputChange}
            />
          </div>
          <TextField
            label="Age"
            variant="outlined"
            name="age"
            fullWidth
            required
            value={formData.age}
            onChange={handleInputChange}
          />
          <TextField
            label="Qualification"
            variant="outlined"
            name="qualification"
            fullWidth
            required
            value={formData.qualification}
            onChange={handleInputChange}
          />
          <TextField
            label="Nationality"
            variant="outlined"
            name="nationality"
            fullWidth
            required
            value={formData.nationality}
            onChange={handleInputChange}
          />
          <FormControl variant="outlined" fullWidth required>
            <InputLabel>Sect</InputLabel>
            <Select
              label="Sect"
              name="sect"
              value={formData.sect}
              onChange={handleInputChange}
            >
              <MenuItem value="Sunni">Sunni</MenuItem>
              <MenuItem value="Brailvi">Brailvi</MenuItem>
              <MenuItem value="Deobandi">Deobandi</MenuItem>
              <MenuItem value="Shia">Shia</MenuItem>
              <MenuItem value="Others">Others</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Caste"
            variant="outlined"
            name="caste"
            fullWidth
            required
            value={formData.caste}
            onChange={handleInputChange}
          />
          <div className="input-group">
            <TextField
              label="Family Origin Country"
              variant="outlined"
              name="familyOriginCountry"
              fullWidth
              required
              value={formData.familyOriginCountry}
              onChange={handleInputChange}
            />
            <TextField
              label="City"
              variant="outlined"
              name="familyOriginCity"
              fullWidth
              required
              value={formData.familyOriginCity}
              onChange={handleInputChange}
            />
          </div>
          <TextField
            label="Contact No"
            variant="outlined"
            name="contactNo"
            fullWidth
            required
            value={formData.contactNo}
            onChange={handleInputChange}
          />
          <TextField
            label="Email"
            variant="outlined"
            name="email"
            fullWidth
            required
            value={formData.email}
            onChange={handleInputChange}
          />

          <Typography variant="h6">Optional Details</Typography>
          <TextField
            label="Family Ref"
            variant="outlined"
            name="familyRef"
            fullWidth
            value={formData.familyRef}
            onChange={handleInputChange}
          />
          <TextField
            label="Father's Name"
            variant="outlined"
            name="fatherName"
            fullWidth
            value={formData.fatherName}
            onChange={handleInputChange}
          />
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Language</InputLabel>
            <Select
              label="Language"
              name="language"
              value={formData.language}
              onChange={handleInputChange}
            >
              <MenuItem value="English">English</MenuItem>
              <MenuItem value="Urdu">Urdu</MenuItem>
              <MenuItem value="Punjabi">Punjabi</MenuItem>
              <MenuItem value="Gujrati">Gujrati</MenuItem>
              <MenuItem value="Others">Others</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Home</InputLabel>
            <Select
              label="Home"
              name="home"
              value={formData.home}
              onChange={handleInputChange}
            >
              <MenuItem value="Home Owner">Home Owner</MenuItem>
              <MenuItem value="Tenant">Tenant</MenuItem>
              <MenuItem value="Living with parents">
                Living with parents
              </MenuItem>
              <MenuItem value="Self">Self</MenuItem>
              <MenuItem value="Others">Others</MenuItem>
            </Select>
          </FormControl>
          <div className="checkbox-label">
            <Checkbox
              name="ownBusiness"
              checked={formData.ownBusiness}
              onChange={handleInputChange}
            />
            <Typography variant="body1" component="span">
              Own a business or property in UK or outside UK
            </Typography>
          </div>
          <div className="radio-group">
            <FormControl component="fieldset">
              <Typography variant="body1" gutterBottom>
                Driving Passed:
              </Typography>
              <RadioGroup
                row
                name="driving"
                value={formData.driving}
                onChange={handleInputChange}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="radio-group">
            <FormControl component="fieldset">
              <Typography variant="body1" gutterBottom>
                Any Disability:
              </Typography>
              <RadioGroup
                row
                name="anyDisability"
                value={formData.anyDisability}
                onChange={handleInputChange}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Marital Status</InputLabel>
            <Select
              label="Marital Status"
              name="maritalStatus"
              value={formData.maritalStatus}
              onChange={handleInputChange}
            >
              <MenuItem value="Single">Single</MenuItem>
              <MenuItem value="Married">Married</MenuItem>
              <MenuItem value="Divorced">Divorced</MenuItem>
              <MenuItem value="Widowed">Widowed</MenuItem>
              <MenuItem value="Others">Others</MenuItem>
            </Select>
          </FormControl>

          {/* Conditional rendering for "Are they live with you" RadioGroup */}
          {formData.maritalStatus !== "Single" && (
            <div className="radio-group">
              <FormControl component="fieldset">
                <TextField
                  label="No of Children"
                  variant="outlined"
                  name="numOfChildren"
                  fullWidth
                  value={formData.numOfChildren}
                  onChange={handleInputChange}
                />
              </FormControl>
            </div>
          )}

          {/* Conditional rendering for "Are they live with you" RadioGroup */}
          {formData.maritalStatus !== "Single" && (
            <div className="radio-group">
              <FormControl component="fieldset">
                <Typography variant="body1" gutterBottom>
                  Are they live with you:
                </Typography>
                <RadioGroup
                  row
                  name="childrenLiveWithYou"
                  value={formData.childrenLiveWithYou}
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>
          )}

          <div className="checkbox-label">
            <FormControlLabel
              control={
                <Checkbox
                  name="hobbies"
                  checked={formData.hobbies}
                  onChange={handleInputChange}
                />
              }
              label="Smoking"
            />
          </div>

          <img src={background} alt="Background" className="background-image" />
        </div>

        <div className="full-width-container">
          <TextField
            label="Please Write down brief note about yourself / your desired Match (Rishta)"
            variant="outlined"
            name="notes"
            multiline
            rows={4}
            fullWidth
            value={formData.notes}
            onChange={handleInputChange}
          />
          <div className="checkbox-label">
            <Checkbox
              name="agreed"
              checked={agreed}
              onChange={(e) => setAgreed(e.target.checked)}
            />
            <Typography variant="body1" component="span">
              I Confirm and Understand the provided details
            </Typography>
          </div>
          
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className="button"
            onClick={handleButtonClick}
            disabled={!agreed}
          >
            Submit Form
          </Button>

          {isFormSubmitted && (
            <Typography variant="subtitle1" className="success-message">
              Form submitted successfully!
            </Typography>
          )}
        </div>
      </Container>
    </div>
  );
};

export default MatrimonialRegistrationForm;
