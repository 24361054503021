
// export default Navbar;
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import LogoutIcon from "@mui/icons-material/Logout"; // Import logout icon for display
import Logo from "../Assets/logo.png";
import "../Components/Navbar.css";
import { HiMenu } from "react-icons/hi";
import LoginForm from './LoginForm'; // Ensure this import is correct

const Navbar = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [openMenu, setOpenMenu] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [openLoginForm, setOpenLoginForm] = useState(false);
  const navigate = useNavigate(); // Hook for programmatic navigation
  
  // This effect listens for changes to localStorage to update login status
  useEffect(() => {
    const handleStorageChange = () => {
      setIsLoggedIn(!!localStorage.getItem('token'));
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleLogout = () => {
    
    localStorage.removeItem('token');
   
    setIsLoggedIn(false);
    navigate('/'); // Optionally navigate to home page upon logout
  };

  const menuOptions = [
    {
      text: "Home",
      icon: <HomeIcon />,
      link: "/",
    },
    ...(isLoggedIn? [
      {
        text: "Gallery",
        icon: <CommentRoundedIcon />,
        link: "/image-gallery", // Link to the Image Gallery page
      },
      
      {
        text: "Matrimonial",
        icon: <CommentRoundedIcon />,
        link: "/matrimonial-table",
      },
      {
        text: "Contact",
        icon: <PhoneRoundedIcon />,
        link: "/contact-table",
      },
      {
        text: "Logout",
        icon: <LogoutIcon />,
        action: handleLogout, // Adding an action for logout
      },
    ] : [
      {
        text: "Books",
        icon: <InfoIcon />,
        link: "/books",
      },
      {
        text: "Education",
        icon: <InfoIcon />,
        link: "/Education",
      },
      
      {
        text: "Gallery",
        icon: <CommentRoundedIcon />,
        link: "/Gallery",
      },
      
      {
        text: "Matrimonial",
        icon: <CommentRoundedIcon />,
        link: "/Matrimonial",
      },
      {
        text: "Contact",
        icon: <PhoneRoundedIcon />,
        link: "/contact",
      },
      {
        text: "About",
        icon: <InfoIcon />,
        link: "/About",
      },
      {
        text: "Login",
        action: () => setOpenLoginForm(true),
      },
    ]),
  ];
  const renderMenuItems = () => menuOptions.map((item) => {
    if (item.action) {
      return (
        <ListItem key={item.text} disablePadding>
          <ListItemButton onClick={item.action}>
         
            <ListItemText primary={item.text} />
          </ListItemButton>
        </ListItem>
      );
    } else {
      return (
        <Link key={item.text} to={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
          <ListItem >
            <ListItemButton>
             
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        </Link>
      );
    }
  });

  const renderDesktopMenu = () => (
    <div className="navbar-links-container">
      {renderMenuItems()}
     
    </div>
  );

  const renderMobileMenu = () => (
    <div className="navbar-menu-container">
      <HiMenu onClick={() => setOpenMenu(true)} />
      <Drawer
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        anchor="right"
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item, index) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton component={item.action ? "button" : Link} to={item.link || ""} onClick={item.action}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
    </div>
  );

  return (
    <nav>
      <div className="nav-logo-container">
        <img src={Logo} alt="Logo" />
    
      </div>
      <div className="header">
        <h2 className='bkm1'>BAZM-E-KHIZAR MILLAT</h2>
    <h1 className='com2'>Community Group</h1>
    </div>
      {!isMobile ? renderDesktopMenu() : renderMobileMenu()}
      <LoginForm open={openLoginForm} handleClose={() => setOpenLoginForm(false)} />
    </nav>
  );
};

export default Navbar;
