import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./App.css";
import Home from "./Components/Home";
import Books from "./Components/Books";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import Gallery from "./Components/Gallery";
import Education from "./Components/Education";
import Matrimonial from "./Components/MatrimonialRegistrationForm";
import MatrimonialTable from './Components/MatrimonialTable';
import ContactTable from './Components/ContactTable';
import ImageGallery from './Components/Image_Gallery'; 
import About from './Components/About';
import { AuthProvider  } from './Components/AuthContext';
import React, { useState, useEffect } from 'react';

import LoginForm from './Components/LoginForm'; // Assuming you have a separate login form component
// Import other necessary components

// Define your theme
const theme = createTheme();

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <div className="App">
            <Navbar isLoggedIn={isLoggedIn} />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/books" element={<Books />} />
              <Route path="/education" element={<Education />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/matrimonial" element={<Matrimonial />} />
              <Route path="/About" element={<About />} />
              <Route path="/image-gallery" element={<ImageGallery />} /> 
              <Route path="/matrimonial-table" element={<MatrimonialTable />} />
              <Route path="/contact-table" element={<ContactTable />} />
              <Route path="/login" element={<LoginForm />} />
            </Routes>
            <Footer />
          </div>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
