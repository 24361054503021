

import React, { useState, useEffect } from 'react';
import styles from './MatrimonialTable.module.css'; // Adjust the path as necessary

const MatrimonialTable = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://www.bkmcommunity.co.uk/api/matrimonial');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const jsonData = await response.json();
                console.log("Fetched data:", jsonData); // Make sure this is after you've awaited the JSON
                setData(jsonData);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };
    
        fetchData();
    }, []);
    

    // Render the table
    return (
        <main className="t-main-content">
        <table className={styles.table}>
            <thead>
                <tr className={styles.tr}>
                    <th className={styles.th}>Name</th>
                    <th className={styles.th}>City</th>
                    <th className={styles.th}>Area</th>
                    <th className={styles.th}>Age</th>
                    <th className={styles.th}>Qualification</th>
                    <th className={styles.th}>Nationality</th>
                    <th className={styles.th}>Sect</th>
                    <th className={styles.th}>Caste</th>
                    <th className={styles.th}> Country</th>
                  
                    <th className={styles.th}>Contact No</th>
                    <th className={styles.th}>Email</th>
                    <th className={styles.th}>Father Name</th>
                    <th className={styles.th}>Language</th>
                    <th className={styles.th}>Home</th>
                    <th className={styles.th}>Own Business</th>
                   
                    <th className={styles.th}>Any Disability</th>
                    <th className={styles.th}>Marital Status</th>
                    <th className={styles.th}>Num of Children</th>
                   
                    <th className={styles.th}>Hobbies</th>
                    <th className={styles.th}>Notes</th>
                </tr>
            </thead>
            <tbody>
                {data.map((item) => (
                    <tr className={styles.tr} key={item._id}>
                        <td className={styles.td}>{item.name}</td>
                        <td className={styles.td}>{item.livingInCity}</td>
                       
                        <td className={styles.td}>{item.age}</td>
                        <td className={styles.td}>{item.qualification}</td>
                        <td className={styles.td}>{item.nationality}</td>
                        <td className={styles.td}>{item.sect}</td>
                        <td className={styles.td}>{item.caste}</td>
                        <td className={styles.td}>{item.familyOriginCountry}</td>
                        <td className={styles.td}>{item.familyOriginCity}</td>
                        <td className={styles.td}>{item.contactNo}</td>
                        <td className={styles.td}>{item.email}</td>
                      
                        <td className={styles.td}>{item.fatherName}</td>
                        <td className={styles.td}>{item.language}</td>
                        <td className={styles.td}>{item.home}</td>
                        <td className={styles.td}>{item.ownBusiness ? 'Yes' : 'No'}</td>
                      
                        <td className={styles.td}>{item.anyDisability}</td>
                        <td className={styles.td}>{item.maritalStatus}</td>
                        <td className={styles.td}>{item.numOfChildren}</td>
                   
                        <td className={styles.td}>{item.hobbies ? 'Yes' : 'No'}</td>
                        <td className={styles.td}>{item.notes}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        </main>
    );
};

export default MatrimonialTable;

