

  import {React} from 'react';
  import {  Link } from "react-router-dom";

  import {Container,  } from '@mui/material';
  import Slider from 'react-slick';
  import "slick-carousel/slick/slick.css"; 
  import "slick-carousel/slick/slick-theme.css";
  import ayat from '../Assets/ayat.png';
  import image1 from '../Assets/1.jpg';
  import image2 from '../Assets/2.jpg';
  import image3 from '../Assets/3.jpg';
  import image4 from '../Assets/4.jpg';
  import image5 from '../Assets/5.jpg';
  import image from '../Assets/6.jpg';
  import image7 from '../Assets/7.jpg';
  import image8 from '../Assets/8.jpg';
  import s from '../Assets/ss.png';
  import s1 from '../Assets/ss1.png';
  import s2 from '../Assets/ss2.png';
  import s3 from '../Assets/ss3.png';
  import s4 from '../Assets/ss4.png';
  import s5 from '../Assets/ss5.png';
  import Gallery1 from '../Assets/a.png';
  import Gallery2 from '../Assets/b.png';
  import Gallery3 from '../Assets/c.png';
  import Gallery4 from '../Assets/d.png';
  import Gallery5 from '../Assets/e.png';
  import Gallery6 from '../Assets/f.png';
  import '../Components/Home.css'

  const HomePage = () => {
    // Slider settings
 
    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
    };

    // Dummy slider data
    const slides = [
      { id: 1, title: "Community Event", image: image1},
      { id: 2, title: "Islamic Teaching", image: image2 },
      { id: 7, title: "Islamic Teaching", image: image7 },
      { id: 8, title: "Islamic Teaching", image: image8 },
      { id: 3, title: "Islamic Teaching", image: image3 },
      { id: 4, title: "Islamic Teaching", image: image4 },
      { id: 5, title: "Islamic Teaching", image: image5 },
      { id: 6, title: "Islamic Teaching", image: image },
      // Add more slides
    ];

    function ServiceItem({ imageSrc, heading, href, description }) {
      return (
      
        <div className="service" id='service'>
          <div className="image">
            <img src={imageSrc} alt="" />
          </div>
          <div className="content">
            <a className="main-heading" href={href}>{heading}</a>
            <p>{description}</p>
          </div>
        </div>
      
      );
    }

    function PillarItem({ icon, title, description }) {
      return (
        <div className="pillar-item">
          <div className="icon">
            {icon}
          </div>
          <h4>{title}</h4>
          <p>{description}</p>
        </div>
      );
    }
    return (
      <Container  maxWidth={false} sx={{ padding: '0 24px' ,mt: '100px'}}>
          
          {/* Detail Slider */}
          <Slider {...sliderSettings}>
            {slides.map((slide) => (
              <div key={slide.id}>
                <img src={slide.image} alt={slide.title} style={{ width: '100%', maxHeight: '500px', objectFit: 'cover' }} />
              </div>
            ))}
          </Slider>
          <div class="about">
  <div class="heading">
    <div class="content">
      <h2 className='abt'>about us</h2>
      <div class="design">
        <span></span>
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 640 512"
          class="icon"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 480c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V160H0v320zm579.16-192c17.86-17.39 28.84-37.34 28.84-58.91 0-52.86-41.79-93.79-87.92-122.9-41.94-26.47-80.63-57.77-111.96-96.22L400 0l-8.12 9.97c-31.33 38.45-70.01 69.76-111.96 96.22C233.79 135.3 192 176.23 192 229.09c0 21.57 10.98 41.52 28.84 58.91h358.32zM608 320H192c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h32v-64c0-17.67 14.33-32 32-32s32 14.33 32 32v64h64v-72c0-48 48-72 48-72s48 24 48 72v72h64v-64c0-17.67 14.33-32 32-32s32 14.33 32 32v64h32c17.67 0 32-14.33 32-32V352c0-17.67-14.33-32-32-32zM64 0S0 32 0 96v32h128V96c0-64-64-96-64-96z"></path>
        </svg>
        <span></span>
      </div>
    </div>
  </div>

  <div class="box-container">
    <div class="image-i">
      {/* <div class="sub-image one">
        <img src="https://siraj-react.asdesignsgalaxy.com/static/media/About-1.1ebc52baf2dd2e6996b5.png" alt="" />
      </div> */}
      <div class="sub-image two">
        {/* <img src="https://siraj-react.asdesignsgalaxy.com/static/media/About-2.3c32bada87cecbf90165.png" alt="" /> */}
        <img src="https://bkmstore.s3.amazonaws.com/a3.jpg" alt="" />
      </div>
    </div>
    <div class="content">
    <h2 className='bkm'>BAZM-E-KHIZAR MILLAT</h2>
    <h1 className='com'>Community Group</h1>
      <h3>Our Vision</h3>
      <p>"Our vision for spiritualism transcends boundaries, embracing diversity and unity in the pursuit of higher consciousness, compassion, and universal love. We aspire to inspire individuals to awaken their spiritual potential, leading to a world of profound understanding, empathy, and interconnectedness.”</p>
      <div>
  <img src={ayat} alt="Small" className="small-image" />
</div>

      <Link to="/Contact" className="btn">Contact Us</Link>
    </div>
  </div>
</div>

  <div className='services' id='services-section'>
  <div className="heading">
    <div className="content">
      <h2>our services</h2>
      <div className="design">
        <span></span>
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 640 512"
          className="icon"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 480c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V160H0v320zm579.16-192c17.86-17.39 28.84-37.34 28.84-58.91 0-52.86-41.79-93.79-87.92-122.9-41.94-26.47-80.63-57.77-111.96-96.22L400 0l-8.12 9.97c-31.33 38.45-70.01 69.76-111.96 96.22C233.79 135.3 192 176.23 192 229.09c0 21.57 10.98 41.52 28.84 58.91h358.32zM608 320H192c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h32v-64c0-17.67 14.33-32 32-32s32 14.33 32 32v64h64v-72c0-48 48-72 48-72s48 24 48 72v72h64v-64c0-17.67 14.33-32 32-32s32 14.33 32 32v64h32c17.67 0 32-14.33 32-32V352c0-17.67-14.33-32-32-32zM64 0S0 32 0 96v32h128V96c0-64-64-96-64-96z"></path>
        </svg>
        <span></span>
      </div>
    </div>
  </div>
  <div className="box-container" >
        <ServiceItem
          imageSrc={s}
          heading="Learning Quran"
          href="/Services/Learning Quran"
          description=" We offer comprehensive Quran learning programs tailored to all ages and levels of expertise.
          Our experienced instructors provide personalized guidance to ensure effective learning
          and understanding of the Holy Quran."
        />
        <ServiceItem
          imageSrc={s1}
          heading="Marriage Service"
          href="/Services/Marriage Service"
          description="Our marriage service offers personalized assistance in every step of your journey towards
          a blissful union. From matchmaking to wedding planning, we ensure that your special day
          is filled with love, joy, and cherished memories."
        
    
       />
        <ServiceItem
          imageSrc={s2}
          heading="Counseling"
          href="/Services/Counseling"
          description="Our counseling services provide a safe and supportive environment for individuals and
          families seeking guidance and healing. Our experienced counselors offer personalized
          solutions to help you navigate life's challenges and achieve emotional well-being.
        "
        />
        <ServiceItem
          imageSrc={s3}
          heading="Community service"
          href="/Services/Community service"
          description="Our community service initiatives aim to make a positive impact on society by
          supporting local neighborhoods and addressing pressing social issues. Join us
          in building a stronger and more inclusive community for everyone."
        />
        <ServiceItem
          imageSrc={s4}
          heading="Mosque Development"
          href="/Services/Mosque Development"
          description=" Our mosque development projects strive to enhance the infrastructure and
          facilities of our local mosques, fostering a welcoming environment for
          worshippers and community members alike. Join us in supporting these vital
          initiatives for the betterment of our community."
        />
        <ServiceItem
          imageSrc={s5}
          heading="Funeral Service"
          href="/Services/Funeral Service"
          description=" Our funeral services offer compassionate support and guidance to families
          during their time of loss. From funeral arrangements to bereavement
          counseling, we are here to provide comfort and assistance in honoring your
          loved ones with dignity and respect."
        />
      </div>
      </div>

      <section className="pillars linear-bg">
      <div className="heading">
        <div className="content">
          <h2>pillars of islam</h2>
          <div className="design">
            <span></span>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 640 512"
              className="icon"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 480c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V160H0v320zm579.16-192c17.86-17.39 28.84-37.34 28.84-58.91 0-52.86-41.79-93.79-87.92-122.9-41.94-26.47-80.63-57.77-111.96-96.22L400 0l-8.12 9.97c-31.33 38.45-70.01 69.76-111.96 96.22C233.79 135.3 192 176.23 192 229.09c0 21.57 10.98 41.52 28.84 58.91h358.32zM608 320H192c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h32v-64c0-17.67 14.33-32 32-32s32 14.33 32 32v64h64v-72c0-48 48-72 48-72s48 24 48 72v72h64v-64c0-17.67 14.33-32 32-32s32 14.33 32 32v64h32c17.67 0 32-14.33 32-32V352c0-17.67-14.33-32-32-32zM64 0S0 32 0 96v32h128V96c0-64-64-96-64-96z"></path>
            </svg>
            <span></span>
          </div>
        </div>
      </div>
      <div className="box-container">
        <PillarItem
          icon={<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 384 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M135.652 0c23.625 0 43.826 20.65 43.826 44.8v99.851c17.048-16.34 49.766-18.346 70.944 6.299 22.829-14.288 53.017-2.147 62.315 16.45C361.878 158.426 384 189.346 384 240c0 2.746-.203 13.276-.195 16 .168 61.971-31.065 76.894-38.315 123.731C343.683 391.404 333.599 400 321.786 400H150.261l-.001-.002c-18.366-.011-35.889-10.607-43.845-28.464C93.421 342.648 57.377 276.122 29.092 264 10.897 256.203.008 242.616 0 224c-.014-34.222 35.098-57.752 66.908-44.119 8.359 3.583 16.67 8.312 24.918 14.153V44.8c0-23.45 20.543-44.8 43.826-44.8zM136 416h192c13.255 0 24 10.745 24 24v48c0 13.255-10.745 24-24 24H136c-13.255 0-24-10.745-24-24v-48c0-13.255 10.745-24 24-24zm168 28c-11.046 0-20 8.954-20 20s8.954 20 20 20 20-8.954 20-20-8.954-20-20-20z"></path></svg>}
          title="shadah"
          description="The Shadah is the declaration of faith in Islam, affirming that there is no god but Allah."
        />
        <PillarItem
          icon={<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 384 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 128c35.35 0 64-28.65 64-64S291.35 0 256 0s-64 28.65-64 64 28.65 64 64 64zm-30.63 169.75c14.06 16.72 39 19.09 55.97 5.22l88-72.02c17.09-13.98 19.59-39.19 5.62-56.28-13.97-17.11-39.19-19.59-56.31-5.62l-57.44 47-38.91-46.31c-15.44-18.39-39.22-27.92-64-25.33-24.19 2.48-45.25 16.27-56.37 36.92l-49.37 92.03c-23.4 43.64-8.69 96.37 34.19 123.75L131.56 432H40c-22.09 0-40 17.91-40 40s17.91 40 40 40h208c34.08 0 53.77-42.79 28.28-68.28L166.42 333.86l34.8-64.87 24.15 28.76z"></path></svg>}
          title="prayer"
          description="Prayer is a solemn expression of devotion and communication with the divine, embodying faith, hope, and reverence."
        />
        <PillarItem
          icon={<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z"></path></svg>}
          title="fasting"
          description="Fasting is a spiritual practice of abstaining from food and drink, a form of self-discipline, reflection, and purification."
        />
        <PillarItem
          icon={<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M271.06,144.3l54.27,14.3a8.59,8.59,0,0,1,6.63,8.1c0,4.6-4.09,8.4-9.12,8.4h-35.6a30,30,0,0,1-11.19-2.2c-5.24-2.2-11.28-1.7-15.3,2l-19,17.5a11.68,11.68,0,0,0-2.25,2.66,11.42,11.42,0,0,0,3.88,15.74,83.77,83.77,0,0,0,34.51,11.5V240c0,8.8,7.83,16,17.37,16h17.37c9.55,0,17.38-7.2,17.38-16V222.4c32.93-3.6,57.84-31,53.5-63-3.15-23-22.46-41.3-46.56-47.7L282.68,97.4a8.59,8.59,0,0,1-6.63-8.1c0-4.6,4.09-8.4,9.12-8.4h35.6A30,30,0,0,1,332,83.1c5.23,2.2,11.28,1.7,15.3-2l19-17.5A11.31,11.31,0,0,0,368.47,61a11.43,11.43,0,0,0-3.84-15.78,83.82,83.82,0,0,0-34.52-11.5V16c0-8.8-7.82-16-17.37-16H295.37C285.82,0,278,7.2,278,16V33.6c-32.89,3.6-57.85,31-53.51,63C227.63,119.6,247,137.9,271.06,144.3ZM565.27,328.1c-11.8-10.7-30.2-10-42.6,0L430.27,402a63.64,63.64,0,0,1-40,14H272a16,16,0,0,1,0-32h78.29c15.9,0,30.71-10.9,33.25-26.6a31.2,31.2,0,0,0,.46-5.46A32,32,0,0,0,352,320H192a117.66,117.66,0,0,0-74.1,26.29L71.4,384H16A16,16,0,0,0,0,400v96a16,16,0,0,0,16,16H372.77a64,64,0,0,0,40-14L564,377a32,32,0,0,0,1.28-48.9Z"></path></svg>}
          title="zakat"
          description="Zakat One Of The Five Pillars Of Islam,  the donation of a  portion of  wealth to help those in need."
        />
        <PillarItem
          icon={<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M554.12 83.51L318.36 4.93a95.962 95.962 0 0 0-60.71 0L21.88 83.51A32.006 32.006 0 0 0 0 113.87v49.01l265.02-79.51c15.03-4.5 30.92-4.5 45.98 0l265 79.51v-49.01c0-13.77-8.81-26-21.88-30.36zm-279.9 30.52L0 196.3v228.38c0 15 10.42 27.98 25.06 31.24l242.12 53.8a95.937 95.937 0 0 0 41.65 0l242.12-53.8c14.64-3.25 25.06-16.24 25.06-31.24V196.29l-274.2-82.26c-9.04-2.72-18.59-2.72-27.59 0zM128 230.11c0 3.61-2.41 6.77-5.89 7.72l-80 21.82C37.02 261.03 32 257.2 32 251.93v-16.58c0-3.61 2.41-6.77 5.89-7.72l80-21.82c5.09-1.39 10.11 2.44 10.11 7.72v16.58zm144-39.28c0 3.61-2.41 6.77-5.89 7.72l-96 26.18c-5.09 1.39-10.11-2.44-10.11-7.72v-16.58c0-3.61 2.41-6.77 5.89-7.72l96-26.18c5.09-1.39 10.11 2.44 10.11 7.72v16.58zm176 22.7c0-5.28 5.02-9.11 10.11-7.72l80 21.82c3.48.95 5.89 4.11 5.89 7.72v16.58c0 5.28-5.02 9.11-10.11 7.72l-80-21.82a7.997 7.997 0 0 1-5.89-7.72v-16.58zm-144-39.27c0-5.28 5.02-9.11 10.11-7.72l96 26.18c3.48.95 5.89 4.11 5.89 7.72v16.58c0 5.28-5.02 9.11-10.11 7.72l-96-26.18a7.997 7.997 0 0 1-5.89-7.72v-16.58z"></path></svg>}
          title="hajj"
          description="Annual pilgrimage to Mecca, one of the Five Pillars of Islam, symbolizing unity and equality among Muslims worldwide."
        />
      </div>
    </section>



      {/* <div className="counting">
        <div className="box">
          <div className="icon">
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 480c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V160H0v320zm579.16-192c17.86-17.39 28.84-37.34 28.84-58.91 0-52.86-41.79-93.79-87.92-122.9-41.94-26.47-80.63-57.77-111.96-96.22L400 0l-8.12 9.97c-31.33 38.45-70.01 69.76-111.96 96.22C233.79 135.3 192 176.23 192 229.09c0 21.57 10.98 41.52 28.84 58.91h358.32zM608 320H192c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h32v-64c0-17.67 14.33-32 32-32s32 14.33 32 32v64h64v-72c0-48 48-72 48-72s48 24 48 72v72h64v-64c0-17.67 14.33-32 32-32s32 14.33 32 32v64h32c17.67 0 32-14.33 32-32V352c0-17.67-14.33-32-32-32zM64 0S0 32 0 96v32h128V96c0-64-64-96-64-96z"></path>
            </svg>
          </div>
          <span className="count">20+</span>
          <h3>Mosques</h3>
        </div>
        <div className="box">
          <div className="icon">
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"></path>
              <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"></path>
            </svg>
          </div>
          <span className="count">500+</span>
          <h3>Events</h3>
        </div>
        <div className="box">
          <div className="icon">
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M319.4 320.6L224 416l-95.4-95.4C57.1 323.7 0 382.2 0 454.4v9.6c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-9.6c0-72.2-57.1-130.7-128.6-133.8zM13.6 79.8l6.4 1.5v58.4c-7 4.2-12 11.5-12 20.3 0 8.4 4.6 15.4 11.1 19.7L3.5 242c-1.7 6.9 2.1 14 7.6 14h41.8c5.5 0 9.3-7.1 7.6-14l-15.6-62.3C51.4 175.4 56 168.4 56 160c0-8.8-5-16.1-12-20.3V87.1l66 15.9c-8.6 17.2-14 36.4-14 57 0 70.7 57.3 128 128 128s128-57.3 128-128c0-20.6-5.3-39.8-14-57l96.3-23.2c18.2-4.4 18.2-27.1 0-31.5l-190.4-46c-13-3.1-26.7-3.1-39.7 0L13.6 48.2c-18.1 4.4-18.1 27.2 0 31.6z"></path>
            </svg>
          </div>
          <span className="count">1000+</span>
          <h3>Students</h3>
        </div>
        <div className="box">
          <div className="icon">
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"></path>
            </svg>
          </div>
          <span className="count">200+</span>
          <h3>Volunteers</h3>
        </div>
      </div> */}
          
          
      <section>
      <div className="heading">
        <div className="content">
          <h2>our gallery</h2>
          <div className="design">
            <span></span>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 640 512"
              className="icon"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 480c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V160H0v320zm579.16-192c17.86-17.39 28.84-37.34 28.84-58.91 0-52.86-41.79-93.79-87.92-122.9-41.94-26.47-80.63-57.77-111.96-96.22L400 0l-8.12 9.97c-31.33 38.45-70.01 69.76-111.96 96.22C233.79 135.3 192 176.23 192 229.09c0 21.57 10.98 41.52 28.84 58.91h358.32zM608 320H192c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h32v-64c0-17.67 14.33-32 32-32s32 14.33 32 32v64h64v-72c0-48 48-72 48-72s48 24 48 72v72h64v-64c0-17.67 14.33-32 32-32s32 14.33 32 32v64h32c17.67 0 32-14.33 32-32V352c0-17.67-14.33-32-32-32zM64 0S0 32 0 96v32h128V96c0-64-64-96-64-96z"></path>
            </svg>
            <span></span>
          </div>
        </div>
      </div>
      <div className="gallery">
       
        <div className="box-container">
          <div className="gallery-item double">
            <img src={Gallery1} alt="Gallery 1" />
            <div className="content">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 16 16"
                className="icon"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"></path>
              </svg>
            </div>
          </div>
          <div className="gallery-item double">
            <img src={Gallery6} alt="Gallery 1" />
            <div className="content">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 16 16"
                className="icon"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"></path>
              </svg>
            </div>
          </div>
          <div className="gallery-item double">
            <img src={Gallery2} alt="Gallery 1" />
            <div className="content">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 16 16"
                className="icon"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"></path>
              </svg>
            </div>
          </div>
          <div className="gallery-item double">
            <img src={Gallery3} alt="Gallery 1" />
            <div className="content">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 16 16"
                className="icon"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"></path>
              </svg>
            </div>
          </div>
          
          <div className="gallery-item double">
            <img src={Gallery4} alt="Gallery 1" />
            <div className="content">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 16 16"
                className="icon"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"></path>
              </svg>
            </div>
          </div>
          <div className="gallery-item double">
            <img src={Gallery5} alt="Gallery 1" />
            <div className="content">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 16 16"
                className="icon"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"></path>
              </svg>
            </div>
          </div>
         
          
          {/* Repeat the pattern for other gallery items */}
        </div>
      </div>
    </section>
         

   
       
      </Container>
    );
  };

  export default HomePage;
